@font-face {
  font-family: ArtifaktElement;
  src: url(assets/fonts/artifakt-element.woff);
}

body {
  font-family: ArtifaktElement;
  background-color: #ecf0f3;
  margin: 0;
  overflow-x: auto;
  overflow-x: hidden;

}

.mail-header {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.mail-header img {
  width: 170px;
  height: 60px;
  background-repeat: no-repeat;
}

.main-content {
  text-align: center;
}

#main-title {
  color: #525353;
  font-weight: normal;
  margin-top: 80px;
  margin-bottom: 50px;
}

.main-text {
  color: #525353;
  margin: 5px;
  overflow-wrap: break-word;
}

#insight-page-redirect {
  text-decoration: none !important;
  text-align: center;
  white-space: nowrap;
  background-color: #0696d7;
  border: 1px solid #0696d7;
  border-radius: 3px;
  box-shadow: 0px 0px 2px 0.2px gray;
  font-weight: normal;
  color: white;
  padding: 10px;
}

#insight-page-redirect:hover {
  background-color: #0580b9;
}